import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Img from 'gatsby-image';

import EmailInput from '../EmailInput';
import withWindowListener from '../withWindowListener';

import styles from './index.module.css';

class CTAFullscreen extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    isLarge: PropTypes.bool.isRequired,
    onChangeHasFullscreenCTA: PropTypes.func.isRequired,
  }

  state = {
    hasClickedClose: false,
    initialScroll: 0,
  }

  componentDidMount() {
    window.analytics.track('wfg-modal-shown', {
      version: 'fullscreen',
      position: 'guide',
    });

    this.setState({
      initialScroll: window.pageYOffset || document.documentElement.scrollTop,
    });

    document.body.classList.add('modal-open');
  }

  componentWillUnmount() {
    const {
      hasClickedClose,
      initialScroll,
    } = this.state;

    document.body.classList.remove('modal-open');

    // We need to check this, because Chapter component will handle scroll if email is submitted.
    if (hasClickedClose) {
      window.scroll(0, initialScroll);
    }
  }

  render() {
    const { isLarge } = this.props;

    return (
      <div className={ styles.wrapper }>
        { isLarge && this.renderBlobs() }

        <div className={ styles.inner }>
          <h1 className={ styles.title }>
            Continue reading
            <span className={ styles.gradient }>
              for free
            </span>
          </h1>
          <p className={ styles.copy }>
            Get access to all 100,000 words. The Frame.io Workflow Guide is always
            expanding, always up to date, and always free.
          </p>

          <EmailInput submitBtnLabel='Get access' />

          <div className={ styles.closeBtnWrapper }>
            <button
              className={ styles.closeBtn }
              type='button'
              onClick={ this.handleClose }
            >
              Proceed without email
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBlobs = () => {
    const { data } = this.props;

    return (
      <Fragment>
        <Img
          className={ styles.blob1 }
          fluid={ data.blob1.childImageSharp.fluid }
          style={ { position: 'absolute' } }
        />
        <Img
          className={ styles.blob2 }
          fluid={ data.blob2.childImageSharp.fluid }
          style={ { position: 'absolute' } }
        />
      </Fragment>
    );
  }

  handleClose = () => {
    const { onChangeHasFullscreenCTA } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: 'guide',
      position: 'middle',
      title: 'remove fullscreen CTA',
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    // Make sure to set the internal state before closing the entire CTA.
    this.setState({
      hasClickedClose: true,
    }, () => {
      onChangeHasFullscreenCTA(false);
    });
  }
}

export default compose(
  withWindowListener,
)(CTAFullscreen);
