import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContextConsumer from '../context/ContextConsumer';

import Guide from '../components/Guide';

const GuidePage = props => (
  <ContextConsumer>
    { context => (
      <Guide
        { ...props }
        { ...context.state }
        { ...context.actions }
      />
    ) }
  </ContextConsumer>
);

GuidePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query GuideQuery {
    allWordpressPage(
      sort:{ fields: menu_order }
    ) {
      edges {
        node {
          id
          slug
          title
          chapter_meta {
            chapter_eyebrow
            chapter_subtitle
            chapter_read_time
            chapter_featured_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2800, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              caption
              alt_text
            }
            chapter_content {
              content_type
              content_section_heading {
                content_heading
                content_id
              }
              content_p
              content_list
              content_image
              content_inlineCTA {
                content_title
                content_description
                content_ctaCopy
                content_ctaURL
              }
              content_embed {
                content_id
              }
              content_subtopic_slug
              content_subtopic_title
              content_subtopic_author
              content_subtopic {
                content_type
                content_section_heading {
                  content_heading
                  content_id
                }
                content_p
                content_image
                content_list
                content_inlineCTA {
                  content_title
                  content_description
                  content_ctaCopy
                  content_ctaURL
                }
                content_embed {
                  content_id
                }
                content_subtopic_slug
                content_subtopic_title
                content_subtopic_author
                content_subtopic {
                  content_type
                  content_section_heading {
                    content_heading
                    content_id
                  }
                  content_p
                  content_image
                  content_list
                  content_inlineCTA {
                    content_title
                    content_description
                    content_ctaURL
                    content_ctaCopy
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GuidePage;
