import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import compose from 'recompose/compose';

import { PRIMARY_META_TAGS } from '../../constants/meta';

import Header from '../Header';
import Nav from '../Nav';
import ShareBtns from '../ShareBtns';
import ThemeToggle from '../ThemeToggle';
import withWindowListener from '../withWindowListener';

import '../../styles/index.css';
import styles from './index.module.css';

class ChapterLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object.isRequired,
    isLarge: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (!!window.Intercom && window.Intercom.booted) {
        window.Intercom('shutdown');

        clearInterval(interval);
      }
    }, 100);
  }

  render() {
    const {
      children,
      data,
      isLarge,
    } = this.props;

    return (
      <div>
        <Helmet
          title={ data.site.siteMetadata.title }
          meta={ PRIMARY_META_TAGS }
          html={ { lang: 'en' } }
          link={ [{ rel: 'stylesheet', href: 'https://use.typekit.net/wms7pqf.css' }] }
        />
        <Header isGuide />
        <Nav />
        { isLarge &&
          <div className={ styles.themeToggle }>
            <ThemeToggle />
          </div>
        }
        { isLarge &&
          <div className={ styles.shareBtns }>
            <ShareBtns />
          </div>
        }
        <div>
          {children}
        </div>
      </div>
    );
  }
}

export default compose(
  withWindowListener,
)(ChapterLayout);
