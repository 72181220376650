import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { getSecondaryNavFromData } from '../../../utils/content-helpers';

import SecondaryNav from './SecondaryNav';

import ContextConsumer from '../../../context/ContextConsumer';

const SecondaryNavWrapper = props => (
  <StaticQuery
    query={ graphql`
      query SecondaryNav {
        allWordpressPage(
          sort:{ fields: menu_order }
        ) {
          edges {
            node {
              slug
              chapter_meta {
                chapter_content {
                  content_type
                  content_section_heading {
                    content_id
                    content_nav_heading
                  }
                }
              }
            }
          }
        }
      }
    ` }
    render={ data => (
      <ContextConsumer>
        { context => (
          <SecondaryNav
            { ...props }
            { ...context.state }
            { ...context.actions }
            sections={ getSecondaryNavFromData(data) }
          />
        ) }
      </ContextConsumer>
    ) }
  />
);

export default SecondaryNavWrapper;
