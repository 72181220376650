import React from 'react';

import Guide from './Guide';

import ContextConsumer from '../../context/ContextConsumer';

const GuideWrapper = props => (
  <ContextConsumer>
    { context => (
      <Guide
        { ...props }
        { ...context.state }
      />
    ) }
  </ContextConsumer>
);

export default GuideWrapper;
