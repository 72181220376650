import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import CTAFullscreen from './CTAFullscreen';

import ContextConsumer from '../../context/ContextConsumer';

const CTAFullscreenWrapper = props => (
  <StaticQuery
    query={ graphql`
      query {
        blob1: file(relativePath: { eq: "cta-blob-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 70) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        blob2: file(relativePath: { eq: "cta-blob-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 70) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    ` }
    render={ data => (
      <ContextConsumer>
        { context => (
          <CTAFullscreen
            { ...props }
            { ...context.state }
            { ...context.actions }
            data={ data }
          />
        ) }
      </ContextConsumer>
    ) }
  />
);

export default CTAFullscreenWrapper;
