import React from 'react';

import ShareExpander from './ShareExpander';

import ContextConsumer from '../../context/ContextConsumer';

const ShareExpanderWrapper = props => (
  <ContextConsumer>
    { context => (
      <ShareExpander
        { ...props }
        { ...context.state }
      />
    ) }
  </ContextConsumer>
);

export default ShareExpanderWrapper;
