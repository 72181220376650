import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import ChapterLayout from './ChapterLayout';

const ChapterLayoutWrapper = ({
  children,
}) => (
  <StaticQuery
    query={ graphql`
      query ChapterLayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    ` }
    render={ data => (
      <ChapterLayout data={ data }>
        { children }
      </ChapterLayout>
    ) }
  />
);

ChapterLayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChapterLayoutWrapper;
