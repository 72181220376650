import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import cx from 'classnames';

import PrimaryNav from './PrimaryNav';
import SecondaryNav from './SecondaryNav';
import ShareExpander from '../ShareExpander';
import withScrollListener from '../withScrollListener';
import withWindowListener from '../withWindowListener';

import styles from './index.module.css';

const HERO_HEIGHT = 750;

const Nav = ({
  isLarge,
  scroll,
}) => {
  const [hasFixedNav, setHasFixedNav] = useState(false);

  useEffect(() => {
    if (scroll >= HERO_HEIGHT && !hasFixedNav) {
      setHasFixedNav(true);
    } else if (scroll < HERO_HEIGHT && hasFixedNav) {
      setHasFixedNav(false);
    }
  }, [scroll]);

  const wrapperClasses = cx({
    [styles.wrapper]: true,
    [styles.fixed]: hasFixedNav,
  });

  return (
    <div className={ wrapperClasses }>
      { isLarge &&
        <div className={ styles.primaryNav }>
          <PrimaryNav />
        </div>
      }
      { isLarge &&
        <div className={ styles.secondaryNav }>
          <SecondaryNav />
        </div>
      }
      { !isLarge &&
        <div className={ styles.shareExpander }>
          <ShareExpander />
        </div>
      }
    </div>
  );
};

Nav.propTypes = {
  isLarge: PropTypes.bool.isRequired,
  scroll: PropTypes.number.isRequired,
};

export default compose(
  withScrollListener,
  withWindowListener,
)(Nav);
