import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import openSocialLink from '../../utils/open-social-link';

import ICONS from '../../constants/icons';
import LINKS from '../../constants/links';
import { GUIDE_SOCIAL, SOCIAL_TYPES } from '../../constants/social';

import Icon from '../Icon';

import styles from './index.module.css';

class ShareExpander extends Component {
  static propTypes = {
    activeChapter: PropTypes.string.isRequired,
  }

  state = {
    isExpanded: false,
  }

  render() {
    const { isExpanded } = this.state;

    return (
      <div className={ styles.wrapper }>
        <button
          className={ styles.triggerBtn }
          type='button'
          onClick={ this.handleTriggerBtnClick }
        >
          <div className={ styles.triggerBtnInner }>
            { isExpanded ?
              <Icon className={ styles.triggerBtnIconClose } icon={ ICONS.CLOSE_BTN } /> :
              <Icon className={ styles.triggerBtnIconOpen } icon={ ICONS.SHARE } />
            }
          </div>
        </button>
        { this.renderShareButtonWrapper() }
      </div>
    );
  }

  renderShareButtonWrapper = () => (
    <div className={ styles.shareBtnWrapper }>
      { this.renderShareButton(SOCIAL_TYPES.EMAIL) }
      { this.renderShareButton(SOCIAL_TYPES.FACEBOOK) }
      { this.renderShareButton(SOCIAL_TYPES.TWITTER) }
    </div>
  )

  renderShareButton = type => {
    const { isExpanded } = this.state;

    return (
      <CSSTransition
        in={ isExpanded }
        classNames='shareBtnTransition'
        timeout={ 200 }
        unmountOnExit
      >
        <button
          className={ styles.shareBtn }
          onClick={ () => this.handleShareBtnClick(type) }
          type='button'
        >
          <div className={ styles.shareBtnInner }>
            <Icon className={ styles.shareBtnIcon } icon={ ICONS[type] } />
          </div>
        </button>
      </CSSTransition>
    );
  }

  handleTriggerBtnClick = () => {
    const { activeChapter } = this.props;
    const { isExpanded } = this.state;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ activeChapter }`,
      position: 'bottom right',
      title: isExpanded ? 'close share expander' : 'open share expander',
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    this.setState({
      isExpanded: !isExpanded,
    });
  }

  handleShareBtnClick = type => {
    const { activeChapter } = this.props;

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ activeChapter }`,
      position: 'bottom right',
      title: `share: ${ type }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    openSocialLink(
      LINKS.SOCIAL[type]({
        ...GUIDE_SOCIAL[type],
        url: window.location.href,
      }),
      type,
    );
  }
}

export default ShareExpander;
