import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import {
  getChapterFromSlug,
  getPreviousSectionFromChapter,
} from '../../utils/content-helpers';

import { GuideProvider } from '../GuideContext';

import Chapter from '../Chapter';
import ChapterLayout from '../ChapterLayout';
import CTAFullscreen from '../CTAFullscreen';

const TIMER_DURATION = 10000;

class Guide extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    hasFullscreenCTA: PropTypes.bool.isRequired,
    isContentGated: PropTypes.bool.isRequired,
    onChangeHasFullscreenCTA: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      data,
      onChangeContextState,
      onChangeHasFullscreenCTA,
    } = this.props;
    const chapterDataArray = data.allWordpressPage.edges;
    const selectedChapter = chapterDataArray[0];

    const params = queryString.parse(window.location.hash);

    let currentChapter = params.ch || '';
    if (currentChapter === selectedChapter.node.slug) {
      currentChapter = '';
    }

    const currentSection = params.s || '';

    onChangeContextState({
      activeChapter: selectedChapter.node.slug,
      activeSection: '',
      scrollToChapter: currentSection ? '' : currentChapter,
      scrollToSection: currentSection,
    });

    if (!currentChapter) {
      window.analytics.track('wfg-chapter-viewed', { chapter: selectedChapter.node.slug });
    }

    window.setTimeout(() => {
      onChangeHasFullscreenCTA(true);
    }, TIMER_DURATION);
  }

  render() {
    const {
      data,
      hasFullscreenCTA,
      isContentGated,
    } = this.props;

    const chapterDataArray = data.allWordpressPage.edges;

    const shouldShowFullscreenCTA = isContentGated && hasFullscreenCTA;

    return (
      <GuideProvider value={ {
        actions: {
          onChangeGuideActiveChapter: this.handleChangeActiveChapter,
          onChangeGuideActiveSectionToPrevious: this.handleChangeActiveSectionToPrevious,
        },
      } }
      >
        <ChapterLayout>
          { chapterDataArray.map((chapterData, i) => {
            // Hacky way to hide Example Page - remove later
            if (chapterData.node.slug === 'example-page') {
              return null;
            }

            return (
              <Chapter
                { ...chapterData.node }
                key={ chapterData.node.id }
                index={ i }
              />
            );
          }) }
        </ChapterLayout>

        { shouldShowFullscreenCTA && <CTAFullscreen /> }
      </GuideProvider>
    );
  }

  handleChangeActiveChapter = slug => {
    const { onChangeActiveChapter } = this.props;

    onChangeActiveChapter(slug);
  }

  handleChangeActiveSectionToPrevious = sectionID => {
    const {
      activeChapter,
      data,
      onChangeActiveSectionToPrevious,
    } = this.props;

    const selectedChapter = getChapterFromSlug(data.allWordpressPage.edges, activeChapter);
    const activeSection = getPreviousSectionFromChapter(selectedChapter, sectionID);

    onChangeActiveSectionToPrevious(activeSection);
  }
}

export default Guide;
