import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.module.css';

class SecondaryNav extends Component {
  static propTypes = {
    activeChapter: PropTypes.string.isRequired,
    activeSection: PropTypes.string.isRequired,
    hasChapterCTA: PropTypes.bool.isRequired,
    isContentGated: PropTypes.bool.isRequired,
    onChangeScrollToSection: PropTypes.func.isRequired,
    sections: PropTypes.object.isRequired,
    theme: PropTypes.string.isRequired,
  }

  render() {
    const {
      activeChapter,
      activeSection,
      hasChapterCTA,
      isContentGated,
      sections,
      theme,
    } = this.props;

    const isCTAVisible = hasChapterCTA && isContentGated;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.disabled]: isCTAVisible,
      [styles.themeDark]: theme === 'dark',
    });

    const selectedSections = sections[activeChapter];

    return (
      <div className={ wrapperClasses }>
        <ul className={ styles.sectionList }>
          { selectedSections.map(section => {
            const sectionItemClasses = cx({
              [styles.sectionItem]: true,
              [styles.active]: activeSection === section.id,
            });

            return (
              <li className={ sectionItemClasses } key={ section.id }>
                <button
                  className={ styles.sectionBtn }
                  type='button'
                  onClick={ () => this.handleSectionClick(section.id) }
                >
                  <div className={ styles.section }>
                    { section.title }
                  </div>
                </button>
              </li>
            );
          }) }
        </ul>
      </div>
    );
  }

  handleSectionClick = sectionID => {
    const {
      activeChapter,
      hasChapterCTA,
      isContentGated,
      onChangeScrollToSection,
    } = this.props;

    const isCTAVisible = hasChapterCTA && isContentGated;

    if (isCTAVisible) {
      return;
    }

    const clickInfo = {
      client: 'wfg',
      page: `chapter: ${ activeChapter }`,
      position: 'middle right',
      title: `click secondary nav: ${ sectionID }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    onChangeScrollToSection(sectionID);
  }
}

export default SecondaryNav;
